import React from "react";
import "../components/style.scss";
import Helmet from "../components/layout/helmet";
import Svg from "../images/approval.svg";
import { Link, navigate } from "gatsby";

const ConfirmationPage = ({ location }) => {
  const { ref } = location.state || {};

  React.useEffect(() => {
    if (!ref) {
      navigate("/");
    }
  });

  return (
    <div className="homepage">
      <Helmet />
      <div className="confirmation">
        <div className="container">
          <img src={Svg} alt="success" />
          <h2>Thank you for your enquiry.</h2>
          <p>
            Your reference number is{" "}
            <span>{ref ? ref.toUpperCase() : null}</span>. Your request is being
            processed and a respresentative will get back to you soonest.
          </p>
          <Link to="/">Return Home</Link>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
